import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Box, Fade, Grid} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {color} from "src/Config/Theme";
import {Feed} from "@mui/icons-material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import {formHandlerInit} from "src/Handler/FormHandler";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import SelectComponent from "src/Component/SelectComponent";
import ServerStatCPUComponent from "./ServerStatCPUComponent";
import ServerStatRAMComponent from "./ServerStatRAMComponent";
import ServerStatDiskComponent from "./ServerStatDiskComponent";
import ServerStatDiskWriteReadComponent from "./ServerStatDiskWriteReadComponent";

function ServerFormComponent(props) {
    // React
    const [loading, setLoading] = React.useState(false);
    const [optionEnterprises, setOptionEnterprises] = React.useState([]);

    // Router
    const navigate = useNavigate();
    const {server} = useParams();

    // Redux
    const dispatch = useDispatch();

    // Form
    const [form, setForm] = React.useState({
        name: {
            id: 'name',
            label: 'Nom',
            helperText: 'Entrez le nom du serveur.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        ip: {
            id: 'ip',
            label: 'IP',
            helperText: 'Entrez l\'IP du serveur.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        password: {
            id: 'password',
            label: 'Mot de passe',
            helperText: 'Entrez le mot de passe du serveur.',
            type: 'password',
            value: '',
            options: {validation: ['required']}
        },
        enterprise: {
            id: 'enterprise',
            label: 'Entreprise',
            helperText: 'Choisissez l\'entreprise du serveur.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        }
    });
    const handler = formHandlerInit(form, setForm);
    const save = () => {
        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setLoading(true);

            routingApiHandlerFetch({
                route: 'api_administrator_server',
                method: 'POST',
                params: {server: server ?? ''},
                data: handler.getDataApi(),
                success: (response) => {
                    handler.setLoading(false);
                    setLoading(false);
                    dispatch(snackbarReduxOpen({text: 'Serveur enregistrée avec succès.', variant: 'success'}))
                    navigate(routingHandlerGetRoutePathname('administrator_server', {server: response.data.server}));
                },
                error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }
    };

    // Bar + Info
    React.useEffect(() => {
        if (props.server) {
            handler.setDataApi(props.server);
        }
    }, [props.server]);
    React.useEffect(() => {
        routingApiHandlerFetch({
            route: 'api_administrator_server_enum_enterprises',
            method: 'GET',
            success: (response) => {
                setOptionEnterprises(response.data);
            },
            error: () => {
                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
            }
        });
    }, []);

    return (
        <>
            <Fade in={true} {...{timeout: 500}}>
                <Box sx={{width: '100%'}}>
                    <Grid container spacing={2} alignItems="stretch">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ShadowBoxComponent>
                                <Box sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                                    <Feed sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Information
                                </Box>

                                <br/>

                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <TextFieldComponent handler={handler} id={'name'} onSubmit={save}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <TextFieldComponent handler={handler} id={'ip'} onSubmit={save}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <TextFieldComponent handler={handler} id={'password'} onSubmit={save}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <SelectComponent handler={handler} id={'enterprise'} onSubmit={save} options={optionEnterprises}/>
                                    </Grid>
                                </Grid>

                                <br/>

                                <ButtonComponent label={'Enregistrer'} onClick={save} loading={loading}/>
                            </ShadowBoxComponent>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>

            <Fade in={true} {...{timeout: 500}}>
                <Box sx={{width: '100%', marginTop: '16px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                            <ServerStatCPUComponent server={props.server}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                            <ServerStatRAMComponent server={props.server}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                            <ServerStatDiskComponent server={props.server}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
                            <ServerStatDiskWriteReadComponent server={props.server}/>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </>
    );
}

export default ServerFormComponent;
