import React from 'react';
import {Box, Fade, Grid} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {color} from "src/Config/Theme";
import {QueryStats, DriveFileRenameOutline, StackedBarChart, CompareArrows, PhotoSizeSelectSmall, TravelExplore} from "@mui/icons-material";
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import ButtonComponent from "src/Component/ButtonComponent";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";

function ServerExtensionComponent(props) {
    const [disabled, setDisabled] = React.useState(false);
    const [loading, setLoading] = React.useState('');
    const {server} = useParams();
    const dispatch = useDispatch();

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box sx={{width: '100%'}}>
                <Grid container spacing={2} alignItems="stretch">
                    <Grid item xs={12} sm={6} md={3} lg={2} xl={2} sx={{display: 'flex'}}>
                        <ShadowBoxComponent sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%'}}>
                            <Box sx={{color: color.textPrimary, marginBottom: '6px', textAlign: 'center', flexGrow: 1}}>
                                <img
                                    style={{marginBottom: '5px'}}
                                    src={'/images/upgrade.png'}
                                    height={38}
                                    width={50}/><br/>
                                <span style={{fontWeight: '600'}}>Upgrade</span>
                                <p style={{fontSize: '10px'}}>Upgrade désigne le processus de mise à niveau d'un logiciel, d'un système ou d'un package vers une version supérieure, offrant généralement de nouvelles fonctionnalités, des améliorations majeures, ou des changements significatifs par rapport à la version précédente.</p>
                            </Box>
                            <ButtonComponent label={'Mise à niveau'} onClick={() => {
                                setDisabled(true);
                                setLoading('upgrade');
                                routingApiHandlerFetch({
                                    route: 'api_administrator_server_extension_upgrade',
                                    method: 'GET',
                                    params: {server: server ?? ''},
                                    success: (response) => {
                                        dispatch(snackbarReduxOpen({text: 'Serveur mise à niveau avec succès.', variant: 'success'}));
                                        setDisabled(false);
                                        setLoading('');
                                    },
                                    error: (response) => {
                                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}));
                                        setDisabled(false);
                                        setLoading('');
                                    }
                                });
                            }} sx={{marginTop: 'auto'}} disabled={loading !== 'upgrade' && disabled} loading={loading === 'upgrade'}/>
                        </ShadowBoxComponent>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={2} xl={2} sx={{display: 'flex'}}>
                        <ShadowBoxComponent sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%'}}>
                            <Box sx={{color: color.textPrimary, marginBottom: '6px', textAlign: 'center', flexGrow: 1}}>
                                <img
                                    style={{marginBottom: '5px'}}
                                    src={'/images/update.png'}
                                    height={38}
                                    width={50}/><br/>
                                <span style={{fontWeight: '600'}}>Update</span>
                                <p style={{fontSize: '10px'}}>Update fait référence à la mise à jour d'un logiciel ou d'un système pour installer les dernières corrections de bugs, améliorations de performance, nouvelles fonctionnalités, ou correctifs de sécurité, assurant ainsi que le système reste à jour et sécurisé.</p>
                            </Box>
                            <ButtonComponent label={'Mise à jour'} onClick={() => {
                                setDisabled(true);
                                setLoading('update');
                                routingApiHandlerFetch({
                                    route: 'api_administrator_server_extension_update',
                                    method: 'GET',
                                    params: {server: server ?? ''},
                                    success: (response) => {
                                        dispatch(snackbarReduxOpen({text: 'Serveur mise à jour avec succès.', variant: 'success'}));
                                        setDisabled(false);
                                        setLoading('');
                                    },
                                    error: (response) => {
                                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}));
                                        setDisabled(false);
                                        setLoading('');
                                    }
                                });
                            }} sx={{marginTop: 'auto'}} disabled={loading !== 'update' && disabled} loading={loading === 'update'}/>
                        </ShadowBoxComponent>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={2} xl={2} sx={{display: 'flex'}}>
                        <ShadowBoxComponent sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%'}}>
                            <Box sx={{color: color.textPrimary, marginBottom: '6px', textAlign: 'center', flexGrow: 1}}>
                                <img
                                    style={{marginBottom: '8px'}}
                                    src={'/images/docker.png'}
                                    height={35}
                                    width={35}/><br/>
                                <span style={{fontWeight: '600'}}>Docker</span>
                                <p style={{fontSize: '10px'}}>Initialisation d'un serveur docker.</p>
                            </Box>
                            <ButtonComponent label={'Installer'} onClick={() => {
                            }} sx={{marginTop: 'auto'}} disabled={loading !== 'docker' && disabled} loading={loading === 'docker'}/>
                        </ShadowBoxComponent>
                    </Grid>
                </Grid>
            </Box>
        </Fade>
    );
}

export default ServerExtensionComponent;
