import React from 'react';
import {Box, Fade, Typography} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {color} from "src/Config/Theme";
import {Security} from "@mui/icons-material";
import ButtonComponent from "src/Component/ButtonComponent";
import AceEditor from 'react-ace';
import {routingApiHandlerFetch} from "../../../../Handler/RoutingApiHandler";
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import {snackbarReduxOpen} from "../../../../Redux/snackbarRedux";
import {useDispatch} from "react-redux";

function ServerFirewallComponent(props) {
    // React
    const [loading, setLoading] = React.useState(false);
    const [code, setCode] = React.useState('');
    const [hasReconfigure, setHasReconfigure] = React.useState(false);

    // Redux
    const dispatch = useDispatch();

    // Form
    const onChange = (newValue) => {
        setCode(newValue);
    };
    const save = (callback = false) => {
        setLoading(true);

        routingApiHandlerFetch({
            route: 'api_administrator_server_firewall',
            method: 'POST',
            params: {server: props.server.uuid},
            data: {scriptFirewall: code},
            success: () => {
                if (callback) {
                    callback();
                }
                else {
                    setLoading(false);
                    dispatch(snackbarReduxOpen({text: 'Firewall enregistré avec succès.', variant: 'success'}));
                }
            },
            error: () => {
                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                setLoading(false);
            }
        });
    };
    const reconfigure = () => {
        save(() => {
            setLoading(true);
            routingApiHandlerFetch({
                route: 'api_administrator_server_firewall_reconfigure',
                method: 'GET',
                params: {server: props.server.uuid},
                success: () => {
                    setLoading(false);
                    setHasReconfigure(true);
                    dispatch(snackbarReduxOpen({text: 'Firewall en cours de reconfiguration...', variant: 'info'}))
                },
                error: () => {
                    dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                    setLoading(false);
                }
            });
        });
    };

    React.useEffect(() => {
        setLoading(true);
        const fetchData = () => {
            if (props.server) {
                routingApiHandlerFetch({
                    route: 'api_administrator_server_firewall',
                    params: {server: props.server.uuid},
                    method: 'GET',
                    success: (response) => {
                        setCode(response.data.scriptFirewall);
                        setHasReconfigure(response.data.hasReconfigure);
                        setTimeout(() => setLoading(false), 750);
                    },
                    error: () => {
                        setTimeout(() => setLoading(false), 750);
                    }
                });
            }
            else {
                setLoading(false);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 1000 * 60);

        return () => clearInterval(intervalId);
    }, [props.server]);

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box sx={{width: '100%'}}>
                <ShadowBoxComponent>
                    <Typography sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                        <Security sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Iptables
                    </Typography>

                    <br/>

                    <AceEditor
                        style={{width: '100%', height: 'calc(100vh - 300px)'}}
                        mode="ruby"
                        theme="solarized_dark"
                        onChange={onChange}
                        name="firewall"
                        editorProps={{$blockScrolling: true}}
                        value={code}
                        setOptions={{
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                    />

                    <br/>

                    <ButtonComponent label={'Enregistrer'} onClick={() => save()} loading={loading} sx={{marginBottom: '10px'}}/>
                    <ButtonComponent
                        label={hasReconfigure ? 'Reconfiguration en cours...' : 'Enregistrer & Reconfigurer'}
                        onClick={reconfigure}
                        disabled={hasReconfigure}
                        loading={loading}
                        color={color.secondary}
                        colorHover={color.secondaryHover}
                    />
                </ShadowBoxComponent>
            </Box>
        </Fade>
    );
}

export default ServerFirewallComponent;
