import React from 'react';
import {Box, Fade} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {color} from "src/Config/Theme";
import {Biotech} from "@mui/icons-material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import {useDispatch} from "react-redux";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";

function ServerAntimalwareComponent(props) {
    // React
    const [loading, setLoading] = React.useState(false);
    const [hasAntimalware, setHasAntimalware] = React.useState(false);
    const [reportAntimalware, setReportAntimalware] = React.useState('');

    // Redux
    const dispatch = useDispatch();

    const save = (hasAntimalware) => {
        routingApiHandlerFetch({
            route: hasAntimalware ? 'api_administrator_server_antimalware_enable' : 'api_administrator_server_antimalware_disable',
            method: 'GET',
            params: {server: props.server.uuid},
            success: () => {
                setLoading(false);
                if (hasAntimalware) {
                    dispatch(snackbarReduxOpen({text: 'Antimalware activé.', variant: 'success'}));
                }
                else {
                    dispatch(snackbarReduxOpen({text: 'Antimalware désactivé.', variant: 'warning'}));
                }
            },
            error: () => {
                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                setLoading(false);
            }
        });
    };

    React.useEffect(() => {
        setLoading(true);
        const fetchData = () => {
            if (props.server) {
                routingApiHandlerFetch({
                    route: 'api_administrator_server_antimalware_info',
                    params: {server: props.server.uuid},
                    method: 'GET',
                    success: (response) => {
                        setHasAntimalware(response.data.hasAntimalware);
                        setReportAntimalware(response.data.reportAntimalware.replace(/\n/g, '</br>'));
                        setTimeout(() => setLoading(false), 750);
                    },
                    error: () => {
                        setTimeout(() => setLoading(false), 750);
                    }
                });
            }
            else {
                setLoading(false);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 1000 * 60);

        return () => clearInterval(intervalId);
    }, [props.server]);

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box sx={{width: '100%'}}>
                <ShadowBoxComponent>
                    <Box sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                        <Biotech sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Rapport d'analyse
                    </Box>

                    <div className="checkbox-wrapper-8" style={{position: 'absolute', top: '15px', right: '25px'}}>
                        <label className="bb8-toggle">
                            <input className="bb8-toggle__checkbox" type="checkbox" defaultChecked={!hasAntimalware} checked={!hasAntimalware} onChange={() => {
                                setHasAntimalware(!hasAntimalware);
                                save(!hasAntimalware);
                            }}/>
                            <div className="bb8-toggle__container">
                                <div className="bb8-toggle__scenery">
                                    <div className="bb8-toggle__star"></div>
                                    <div className="bb8-toggle__star"></div>
                                    <div className="bb8-toggle__star"></div>
                                    <div className="bb8-toggle__star"></div>
                                    <div className="bb8-toggle__star"></div>
                                    <div className="bb8-toggle__star"></div>
                                    <div className="bb8-toggle__star"></div>
                                    <div className="tatto-1"></div>
                                    <div className="tatto-2"></div>
                                    <div className="gomrassen"></div>
                                    <div className="hermes"></div>
                                    <div className="chenini"></div>
                                    <div className="bb8-toggle__cloud"></div>
                                    <div className="bb8-toggle__cloud"></div>
                                    <div className="bb8-toggle__cloud"></div>
                                </div>
                                <div className="bb8">
                                    <div className="bb8__head-container">
                                        <div className="bb8__antenna"></div>
                                        <div className="bb8__antenna"></div>
                                        <div className="bb8__head"></div>
                                    </div>
                                    <div className="bb8__body"></div>
                                </div>
                                <div className="artificial__hidden">
                                    <div className="bb8__shadow"></div>
                                </div>
                            </div>
                        </label>
                    </div>
                </ShadowBoxComponent>

                <br/>

                <ShadowBoxComponent sx={{width: '100%', height: 'calc(100vh - 245px)'}}>
                    <div style={{color: color.textPrimary}} dangerouslySetInnerHTML={{__html: reportAntimalware}}></div>
                </ShadowBoxComponent>
            </Box>
        </Fade>
    );
}

export default ServerAntimalwareComponent;
