import React from 'react';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import {ReactTerminal} from 'react-terminal';
import {TerminalContextProvider} from "react-terminal";
import {useParams} from "react-router-dom";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";

function ServerSSHComponent(props) {
    // React
    const [currentDir, setCurrentDir] = React.useState('/');

    // Router
    const {server} = useParams();

    const executeAPI = async (command, paramsCommand) => {
        return new Promise((resolve, reject) => {
            routingApiHandlerFetch({
                route: 'api_administrator_server_ssh',
                method: 'POST',
                params: {
                    server: server ?? ''
                },
                data: {
                    command: command + ' ' + paramsCommand,
                    currentDir: currentDir
                },
                success: (response) => {
                    let output = response.data.output;
                    let text = '';
                    for(let index in output){
                        text += output[index] + '</br>';
                    }
                    resolve(<div style={{marginBottom: '-21px'}} dangerouslySetInnerHTML={{__html: text}}/>);
                },
                error: (error) => {
                    resolve(`Erreur : ${command}`);
                }
            });
        });
    };

    const commands = {
        cd: async (directory) => {
            let newDir;
            if (directory === '..') {
                newDir = currentDir.substring(0, currentDir.lastIndexOf('/')) || '/';
            }
            else if (directory.startsWith('/')) {
                newDir = directory;
            }
            else {
                newDir = currentDir + '/' + directory;
            }

            return new Promise((resolve, reject) => {
                routingApiHandlerFetch({
                    route: 'api_administrator_server_ssh',
                    method: 'POST',
                    params: {
                        server: server ?? ''
                    },
                    data: {
                        command: 'cd ' + newDir,
                        currentDir: '/'
                    },
                    success: (response) => {
                        console.log(response.data.output);
                        if(response.data.output[0] === 'true' || response.data.output[0] === 'true\n') {
                            resolve();
                            setCurrentDir(newDir);
                        } else {
                            resolve(`Aucun fichier ou dossier de ce type`);
                        }
                    },
                    error: (error) => {
                        resolve(`Aucun fichier ou dossier de ce type`);
                    }
                });
            });
        },
        whoami: 'root',
        echo: (message) => message,
        help: () => 'Available commands: whoami, cd <directory>, echo <message>, help',
    };

    return (
        <TerminalContextProvider>
            <ShadowBoxComponent sx={{padding: 0}}>
                <div style={{width: '100%', height: 'calc(100vh - 165px)'}}>
                    <ReactTerminal
                        commands={commands}
                        prompt={`root@${props.server.ip}:${currentDir}$`}
                        theme={'dark'}
                        defaultHandler={executeAPI}
                    />
                </div>
            </ShadowBoxComponent>
        </TerminalContextProvider>
    );
}

export default ServerSSHComponent;
